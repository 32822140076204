import * as Sentry from '@sentry/browser'
export default defineNuxtPlugin((nuxtApp) => {
    const { public: { sentry } } = useRuntimeConfig()

    if (!sentry.dsn) {
        return {
            provide: {
                sentry: () => { }
            }
        }
    }

    Sentry.init({
        dsn: sentry.dsn,
        environment: sentry.environment,
        // Performance Monitoring
        tracesSampleRate: 0.1, // Change in production!
        // Set sampling rate for profiling - this is relative to tracesSampleRate
        profilesSampleRate: 0.1, // Change in production!
        ignoreErrors: [
            'Failed to fetch', 'Internal error',
            'Non-Error promise rejection',
            '/_nuxt/builds/latest.json',
            'The user credentials were incorrect', 'El usuario no está autenticado', 'Credenciales incorrectas',
            'ResizeObserver', 'Unexpected end of input',
            '[]', 'Unexpected character', 'No error message', 'Unable to preload CSS', '{}',
            'La reserva ya está pagada',
            'El importe es menor que el precio', 'El importe es mayor que el precio', 'The amount is greater than the price', 'The amount is lower than the price',
            'Error con la tarjeta de crédito', 'Esta tarjeta de crédito ya está asignada al usuario',
            'Error al añadir la tarjeta de crédito',
            'Authentication error',
            'The user is not verified', 'The user is not valid',
            'Page not found',
            'No hemos podido iniciar sesión', 'We were unable to log you in',
            'Email could not be sent',
            'Operación no autorizada',
            '404',
            'La cantidad mínima para realizar la reserva',
            'Error sending message',
            'document.cookie is not a function', 'errorCb is not a function' , 'error loading dynamically imported module',
            'Invalid LngLat',
            'Connection to Indexed Database server lost. Refresh the page to try again',
            'Importing a module script failed'
        ]
    })

    return {
        provide: {
            sentry: (error, tag, data = null) => {
                Sentry.configureScope((scope) => {
                    scope.setTag('transaction', tag)
                    if (error?.graphQLErrors?.length) {
                        error.graphQLErrors.forEach((error, index) => {
                            Sentry.addBreadcrumb({
                                category: `graphQLErrors${(index + 1)}`,
                                data: {
                                    graphQLErrors: error, debugMessage: error.debugMessage ? error.debugMessage : error.message
                                }
                            })
                        })
                    }
                    if (data) {
                        Sentry.addBreadcrumb({
                            category: 'data',
                            data
                        })
                    }
                    Sentry.captureException(new Error(JSON.stringify(error)))
                })
            }
        }
    }
})