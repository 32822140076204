<template>
  <el-dialog :model-value="value" modal-class="custom-delete-dialog">
    <template #header>
      <span class="heading4">
        {{
          ['experience', 'activity'].includes(type)
            ? $t('exclusive_type_fem', { type: $t(type) })
            : $t('exclusive_type', { type: $t(type) })
        }}
      </span>
    </template>
    <div class="first-block">
      <span class="body2" v-if="getAuthUser()?.value?.current_membership"
        >{{
          ['experience', 'activity'].includes(type)
            ? $t(
                'this_curated_type_is_reserved_for_a_higher_membership_tier_upgrade_your_membership_to_unlock_access_to_our_complete_collection_of_extraordinary_types_fem',
                {
                  types: $t(
                    type === 'activity'
                      ? 'activities'
                      : `${type}s`
                  ).toLowerCase(),
                  type: $t(type).toLowerCase()
                }
              )
            : $t(
                'this_curated_type_is_reserved_for_a_higher_membership_tier_upgrade_your_membership_to_unlock_access_to_our_complete_collection_of_extraordinary_types',
                {
                  types: $t(`${type}s`).toLowerCase(),
                  type: $t(type).toLowerCase()
                }
              )
        }}
      </span>
      <span class="body2" v-else
        >{{
          ['experience', 'activity'].includes(type)
            ? $t(
                'join_our_distinguished_community_to_access_our_collection_of_extraordinary_types_fem',
                {
                  type: $t(
                    type === 'activity'
                      ? 'activities'
                      : `${type}s`
                  ).toLowerCase()
                }
              )
            : $t(
                'join_our_distinguished_community_to_access_our_collection_of_extraordinary_types',
                { type: $t(`${type}s`).toLowerCase() }
              )
        }}
      </span>
    </div>
    <div class="actions">
      <ElButton type="secondary dark" @click="$emit('close')">
        {{ $t('close') }}
      </ElButton>
      <ElButton
        type="primary dark"
        @click="goToMemberships()"
      >
        {{
          getAuthUser()?.value?.current_membership
            ? $t('upgrade_membership')
            : $t('become_a_member')
        }}
      </ElButton>
    </div>
  </el-dialog>
</template>

<script>
export default defineComponent({
  props: {
    type: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter()

    return {
      router
    }
  },
  methods: {
    goToMemberships() {
      this.$emit('close')
      this.router.push('/memberships')
    }
  }
})
</script>

<style>
</style>