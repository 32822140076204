<template>
  <ElConfigProvider :locale="elementPlusLanguage">
    <UtilsHeader
      :is-dark="headerIsDark"
      :dark-background="headerHasDarkBackground"
      :light-background="headerHasLightBackground"
      @showResults="showResults = $event"
    />
    <div class="el-overlay" v-if="showResults" />
    <NuxtPage />
    <div ref="cookieDeclarationRef"></div>
    <UtilsFooter v-if="showFooter" :dark-background="footerHasDarkBackground" />
    <UtilsMembershipDialog
      @close="showMembershipDialog = false"
      :type="membershipType"
      :value="showMembershipDialog"
    />
    <UtilsLoginDialog
      @close="showLoginDialog = false"
      :value="showLoginDialog"
      :redirection-url="temporaryRedirectionUrl"
    />
    <UtilsDonatellaDialog
      @close="showDonatellaDialog = false"
      :value="showDonatellaDialog"
    />
  </ElConfigProvider>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useFavicon } from '@vueuse/core'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'
import fr from 'element-plus/es/locale/lang/fr'
import de from 'element-plus/es/locale/lang/de'
import sv from 'element-plus/es/locale/lang/sv'
import ar from 'element-plus/es/locale/lang/ar'
import dayjs from 'dayjs'
dayjs.Ls.en.weekStart = 1

export default defineComponent({
  setup() {
    const user = getAuthUser()
    const route = useRoute()
    const router = useRouter()
    const { $eventBus } = useNuxtApp()
    const { locale } = useI18n({ useScope: 'global' })
    locale.value = user?.value?.language?.toLowerCase()
      ? user?.value?.language?.toLowerCase()
      : navigator.language.includes('es')
      ? 'es'
      : navigator.language.includes('en')
      ? 'en'
      : navigator.language.includes('fr')
      ? 'fr'
      : navigator.language.includes('de')
      ? 'de'
      : navigator.language.includes('sv')
      ? 'sv'
      : navigator.language.includes('ar')
      ? 'ar'
      : 'en'

    return {
      route,
      router,
      locale,
      config: useRuntimeConfig(),
      $eventBus
    }
  },
  data() {
    return {
      elementPlusLanguage: null,
      newColorScheme: 'light',
      showResults: false,
      showMembershipDialog: false,
      membershipType: null,
      showLoginDialog: false,
      temporaryRedirectionUrl: null,
      showDonatellaDialog: false
    }
  },
  watch: {
    newColorScheme() {
      useFavicon(this.favicon, {
        rel: 'icon'
      })
    }
  },
  computed: {
    isUserLogged() {
      return getAuthUser()?.value?.uuid ? true : false
    },
    favicon() {
      return this.newColorScheme === 'dark'
        ? '/favicon.svg'
        : '/favicon-dark.svg'
    },
    headerIsDark() {
      return [
        'welcome',
        'bye',
        'index',
        'founder-onboarding',
        'experiences',
        'experiences-id-success',
        'experiences-id-confirm-booking',
        'experiences-id-booked',
        'activities',
        'activities-id-confirm-booking',
        'activities-id-success',
        'activities-id-booked',
        'tours',
        'tours-id-confirm-booking',
        'tours-id-success',
        'tours-id-booked',
        'accommodations',
        'accommodations-id-confirm-booking',
        'accommodations-id-confirm-booking-room-types',
        'accommodations-id-success',
        'accommodations-id-booked',
        'restaurants',
        'restaurants-id-confirm-booking',
        'restaurants-id-success',
        'restaurants-id-booked',
        'memberships-id-success',
        'destinations',
        'destinations-id',
        'your-itineraries',
        'reservations',
        'reservations-id',
        'account',
        'my-membership',
        'search',
        'nft-wallet',
        'nft-wallet-id'
      ].includes(this.route.name)
    },
    headerHasLightBackground() {
      return [
        'experiences-id',
        'accommodations-id',
        'activities-id',
        'tours-id',
        'restaurants-id',
        'guides-id',
        'your-itineraries-id'
      ].includes(this.route.name)
    },
    headerHasDarkBackground() {
      return [
        'experiences',
        'experiences-id-booked',
        'experiences-id-confirm-booking',
        'activities',
        'activities-id-booked',
        'activities-id-confirm-booking',
        'tours',
        'tours-id-booked',
        'tours-id-confirm-booking',
        'accommodations',
        'accommodations-id-booked',
        'accommodations-id-confirm-booking',
        'accommodations-id-confirm-booking-room-types',
        'restaurants',
        'restaurants-id-booked',
        'restaurants-id-confirm-booking',
        'destinations',
        'your-itineraries',
        'reservations',
        'reservations-id',
        'guides-id',
        'account',
        'my-membership',
        'search',
        'nft-wallet-id'
      ].includes(this.route.name)
    },
    footerHasDarkBackground() {
      return ['nft-wallet', 'nft-wallet-id'].includes(this.route.name)
    },
    showFooter() {
      return ![
        'welcome',
        'experiences',
        'activities',
        'restaurants',
        'accommodations',
        'tours',
        'destinations',
        'search',
        'experiences-id-success',
        'activities-id-success',
        'restaurants-id-success',
        'accommodations-id-success',
        'tours-id-success',
        'memberships-id-success',
        'bye',
        'donatella'
      ].includes(this.route.name)
    }
  },
  mounted() {
    this.setColorScheme()
    this.setElementPlusLocale()
    this.$eventBus.$on('setLocale', ($event) => {
      this.$i18n.locale = $event
      localStorage.setItem('languageSelected', $event)
      this.setElementPlusLocale(true)
    })
    this.$eventBus.$on('showMembershipDialog', ($event) => {
      this.showMembershipDialog = true
      this.membershipType = $event
    })
    this.$eventBus.$on('showLoginDialog', ($event) => {
      this.showLoginDialog = true
      this.temporaryRedirectionUrl = $event
    })
    this.$eventBus.$on('showDonatellaDialog', () => {
      this.showDonatellaDialog = true
    })
    const script = document.createElement('script')
    script.src = 'https://consent.cookiebot.com/uc.js'
    script.setAttribute('data-cbid', this.config.public.cookieBotId)
    script.setAttribute('data-blockingmode', 'manual')
    script.id = 'Cookiebot'
    script.async = true
    document.head.appendChild(script)
  },
  watch: {
    '$route.fullPath'() {
      setTimeout(() => {
        this.setElementPlusLocale()
      }, 0)
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
    this.setColorScheme()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.getContainerSize()
    },
    getContainerSize() {
      this.containerWidth = document.body.clientWidth
    },
    setElementPlusLocale(refresh = false) {
      let language = 'en'
      if (this.isUserLogged && !refresh) {
        language =
          getAuthUser()?.value?.language.toLowerCase() === 'es' ? 'es' : 'en'
        localStorage.setItem('languageSelected', language)
      }
      if (localStorage.getItem('languageSelected')) {
        language = localStorage.getItem('languageSelected')
      } else if (this.route.fullPath === '/community') {
        language = 'es'
      } else {
        language = this.$i18n.locale
      }
      this.$i18n.locale = language
      this.locale = language
      switch (language.toLowerCase()) {
        case 'es':
          this.elementPlusLanguage = es
          break
        case 'en':
          this.elementPlusLanguage = en
          break
        case 'fr':
          this.elementPlusLanguage = fr
          break
        case 'de':
          this.elementPlusLanguage = de
          break
        case 'sv':
          this.elementPlusLanguage = sv
          break
        case 'ar':
          this.elementPlusLanguage = ar
          break
        default:
          this.elementPlusLanguage = en
          break
      }
    },
    setColorScheme() {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        this.newColorScheme = 'dark'
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          this.newColorScheme = event.matches ? 'dark' : 'light'
        })
    }
  }
})
</script>

<style scoped lang="scss"></style>
